import axios from 'axios'
// import { getLogin, removeUserInfo } from '@/utils/index'
// import { ElMessage } from 'element-plus'
// import router from '@/router'
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})

// 添加拦截器
request.interceptors.request.use(function (config) {
  // 获取token 添加到请求头 做接口token校验
  return config
}, function (error) {
  return Promise.reject(error)
})

request.interceptors.response.use(function (response) {
  // 判断后端返回 业务编码 code  401 403 弹出错误提示，清除登录相关缓存  跳转到登录页重新登录

  return response
}, function (error) {
  return Promise.reject(error)
})

export default request
