export default {
  namespaced: true,
  state: {
    language: 0,
    languageVal: 'zh',
    cppath: '/productCenter/bialene',
    fwpath: '/serviceCenter/kit',
    xwpath: '/newsCenter/news'
  },
  mutations: {
    CHANGE_NAV_STATE (state) {
      if (state.language === 1) {
        state.language = 0
        state.languageVal = 'zh'
      } else {
        state.language = 1
        state.languageVal = 'en'
      }
    },
    CHANGE_PATH_STATE (state, newPath) {
      state.cppath = newPath
    },
    CHANGE_PATH_FW (state, newPath) {
      state.fwpath = newPath
    },
    CHANGE_PATH_XW (state, newPath) {
      state.xwpath = newPath
    }
  }
}
