import Vue from 'vue'

import VueI18n from 'vue-i18n'

import zh from './lang/zh'
import en from './lang/en'

Vue.use(VueI18n)
// 准备翻译的语言环境信息
const messages = {
  zh,
  en
}

// 通过选项创建 VueI18n 实例
export default new VueI18n({
  locale: 'zh', // 设置地区
  messages // 设置地区信息
})
