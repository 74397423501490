<template>
  <div class="footerbox">
    <div class="footermain">
      <div class="footerhead">
        <div class="footerheadmain">
          <div class="footertit" v-for="menu in dataList" :key="menu.id">
            <div class="tit" v-if="language === 0 && parseInt(menu.zhIsShow) === 1 ">{{ menu.zhName }}</div>
            <div class="tit" v-if="language === 1 && parseInt(menu.enIsShow) === 1 ">{{ menu.enName }}</div>
            <!-- <div :class="index === 0 ? 'container' : ''"> -->
            <div>
              <div class="footercontent" v-for="subMenu in menu.children" :key="subMenu.id">
                <div v-if="language === 0 && parseInt(subMenu.zhIsShow) === 1 " @click="goPath(subMenu.link)">{{subMenu.zhName}}</div>
                <div v-if="language === 1 && parseInt(subMenu.enIsShow) === 1 " @click="goPath(subMenu.link)">{{subMenu.enName}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="erwm">
          <!-- <div class="erwmlef">
            <div class="imge">
              <img src="../../assets/image/erwmone.png" alt="">
            </div>
            <p>{{ $t("button.jrwm") }}</p>
          </div> -->
          <div class="erwmrig">
            <div class="imge">
              <img src="../../assets/image/erwmtow.png" alt="">
            </div>
            <p>{{ $t("button.lxwm") }}</p>
          </div>
        </div>
      </div>
      <div class="footerfoot">
				<span>innovation-ynwx.com</span> &nbsp;&nbsp;&nbsp;&nbsp;
				<span>| &nbsp;&nbsp;&nbsp;&nbsp;
					<a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer" style="color: #fff;text-decoration: none;">皖ICP备2024047582号</a>
				</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getMenutree } from '@/api/home'
export default {
  name: 'NavigationBar',
  data () {
    return {
      dataList: []
    }
  },
  // created () {
  //   this.getList()
  // },
  mounted () {
    // this.getList()
  },
  computed: {
    language () {
      return this.$store.state.base.language
    }
  },
  watch: {
    language: {
      immediate: true,
      handler: function (newValue, oldValue) {
        this.getList()
      }
    }
  },
  methods: {
    goPath (path) {
      this.$store.commit('base/CHANGE_PATH_STATE', path)
      this.$store.commit('base/CHANGE_PATH_FW', path)
      this.$store.commit('base/CHANGE_PATH_XW', path)
      this.$router.push({
        path: path,
        query: {
          link: path
        }
      })
      if (path.includes('/aboutUs')) {
        const currentpath = '/aboutUs'
        this.act = currentpath
        if (path && document.getElementById(path)) {
          document.getElementById(path).scrollIntoView({ behavior: 'smooth' })
        }
      } else if (path.includes('/serviceCenter')) {
        const currentpath = '/serviceCenter'
        this.act = currentpath
      } else if (path.includes('/productCenter')) {
        const currentpath = '/productCenter'
        this.act = currentpath
      } else if (path.includes('/newsCenter')) {
        const currentpath = '/newsCenter'
        this.act = currentpath
      } else {
        this.act = path
      }
    },
    getList () {
      getMenutree(this.language).then(res => {
        if (res.data.code === 200) {
          this.dataList = res.data.data.slice(1, 5)
        // console.log(this.dataList)
        } else {
          this.dataList = []
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.footerbox{
  font-family: HarmonyOS_Sans_SC_Regular;
  width:100%;
  height:420px;
  background: #172B4D;
  background-image: url(../../assets/image/footerbk.png);
  background-repeat: no-repeat;
  background-position: center;
  .footermain{
    width: 1200px;
    height:420px;
    margin: 0 auto;
    .footerhead{
      border-bottom: 1px solid #fff;
      width: 100%;
      height: 300px;
      padding-top: 48px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .footerheadmain{
        width: 600px;
        height: 160px;
        display: flex;
        justify-content: space-between;
        text-align: start;
        .footertit{
          margin-right: 30px;
          .tit{
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 24px;
          }
          .container{
            // width: 180px;
            column-count: 2; /* 设置为2列 */
            column-gap: 20px; /* 可以根据需要设置列间距 */
            .footercontent{
              // height: 112px;
              break-inside: avoid;
              div{
                cursor: pointer;
              }
            }
          }
          .footercontent{
            margin-bottom: 16px;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
            div{
                cursor: pointer;
              }
          }
        }
      }
      .erwm{
        display: flex;
        justify-content: space-between;
        .erwmlef{
          .imge{
            width: 160px;
            height: 160px;
            padding: 10px;
            box-sizing: border-box;
            background-color: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          p{
            margin-top: 16px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
        .erwmrig{
          margin-left: 40px;
          .imge{
            width: 160px;
            height: 160px;
            padding: 10px;
            box-sizing: border-box;
            background-color: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          p{
            margin-top: 16px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
    }
    .footerfoot{
      display: flex;
      justify-content: center;
      padding-top: 52px;
      box-sizing: border-box;
      height: 119px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}
</style>
