export default {
  message: {
    hello: 'hello world'
  },
  button: {
    add: 'button',
    cklj: 'EVEN MORE',
    ckgd: 'view more',
    ckxq: 'view details',
    ckqb: 'view all',
    lxwm: 'contact us',
    fhml: 'return count',
    jrwm: 'join us',
    xjzx: 'inquiry price'
  },
  gdsj: {
    tit: 'High-end reagent',
    contone: 'Based on the global cutting-edge technology, develop and supply a variety of special reagents',
    conttow: 'Production of fine chemicals based on core technologies',
    contthree: 'Characteristic reagent: biene'
  },
  dzhc: {
    tit: 'Custom synthesis',
    contone: 'Reagent customization for market needs',
    conttow: 'Reagent customization for market needs',
    contthree: 'Custom scale: mg to kg'
  },
  yhjs: {
    tit: 'Core Technology',
    contone: 'Technology research and development: new industrial catalytic technology, drug discovery and drug delivery technology, carbon neutrality and clean technology development',
    conttow: 'Technology development team: Excellent team from Fudan University, Peking University, University of Science and Technology of China, Zhejiang University, East China Normal University, etc',
    contthree: 'Characteristic technology: bialene chemistry, clean oxidation'
  },
  aboutus: {
    address: 'address',
    phone: 'phone',
    email: 'e-mail',
    fixPhone: 'fixed-line telephone'
  },
  honor: {
    clgs: 'founding a company',
    sjcg: 'several reagents were successfully developed',
    hdry: 'achieve distinction',
    fmzl: 'patent for an invention',
    hxjs: 'core technology'
  },
  development: {
    tit: 'Development History',
    contentone: 'Anhui Inaweixun Technology Co., LTD., founded in 2022, is an innovative research and development company jointly initiated by Hefei Xixuan Technology Co., Ltd. and Zhejiang Xinhecheng Co., LTD.',
    contenttow: 'Relying on the original research and development team of universities or institutes and the Xinhecheng industry platform, the company develops the key core technologies of the functional molecule industry chain, and provides advanced technologies and products for the industries of fine chemicals, biomedicine, new energy and new materials.'
  },
  productdetail: {
    productName: 'Product Name',
    zhName: 'Chinese Name',
    casNo: 'CAS NO',
    chemicalFormula: 'Molecular Formula',
    molecularWeight: 'Molecular Weight',
    mdlNo: 'MDL NO',
    boilingPoint: 'Boiling Point',
    pubchemId: 'Pubchem ID',
    inChIKey: 'InChIKey',
    inItemNo: 'Art.No',
    titone: 'Product Information',
    tittow: 'Bialene products other recommendations',
    titthree: 'Pharmaceutical intermediates other recommendations',
		titFour: 'Propargyl alcohol products Other recommended',
		titFive: 'Boric acid products Other recommended',
		titSix: 'Ligand products Other recommendations',
  },
  joinus: {
    zpgs: 'Recruitment agency',
    zpzw: 'Position vacant',
    gzdd: 'Workplace',
    fbrq: 'Release date'
  }
}
