import request from '@/utils/request'

// 获取搜索
export function getProductQuery (search) {
  return request({
    url: '/biz/product/query?search=' + search,
    method: 'get'
  })
}

// 获取官网菜单
export function getMenutree (language) {
  return request({
    url: '/biz/website/menu/front/tree/' + language,
    method: 'get'
  })
}

// 获取官网详情
export function getMenuCompany (language) {
  return request({
    url: '/biz/company/index/' + language,
    method: 'get'
  })
}

// 获取公司详情，获取联系我们
export function getCompanylist (language) {
  return request({
    url: '/biz/company/get/' + language,
    method: 'get'
  })
}

// 获取公司发展
export function getHistorydata (language) {
  return request({
    url: '/biz/develop/history/' + language,
    method: 'get'
  })
}

// 获取公司文化
export function getCulturedata (language) {
  return request({
    url: '/biz/culture/data/' + language,
    method: 'get'
  })
}

// 获取公司管理团队
export function getTeamdata (language) {
  return request({
    url: '/biz/team/data/' + language,
    method: 'get'
  })
}

// 获取职业提升平台
export function getPromotedata (language) {
  return request({
    url: '/biz/promote/data/' + language,
    method: 'get'
  })
}

// 获取公司技术
export function getTechnologydata (language) {
  return request({
    url: '/biz/technology/team/data/' + language,
    method: 'get'
  })
}

// 获取资质荣誉
export function getHonordata (language) {
  return request({
    url: '/biz/honor/data/' + language,
    method: 'get'
  })
}

// 获取服务中心
export function getServicedata (language) {
  return request({
    url: '/biz/service/center/data/' + language,
    method: 'get'
  })
}

// 获取加入我们
export function getRecruitmentdata (language) {
  return request({
    url: '/biz/recruitment/data/' + language,
    method: 'get'
  })
}

// 获取产品列表
export function listProductData (query) {
  return request({
    url: '/biz/product/data',
    method: 'get',
    params: query
  })
}

// 获取产品详情
export function getProductdatadetail (id) {
  return request({
    url: '/biz/product/get/' + id,
    method: 'get'
  })
}

// 获取产品推荐
export function listProductRecommend (query) {
  return request({
    url: '/biz/product/recommend',
    method: 'get',
    params: query
  })
}

// 获取新闻
export function listnewsdata (query) {
  return request({
    url: '/biz/news/data',
    method: 'get',
    params: query
  })
}

// 获取新闻详情
export function getNewsdetail (id) {
  return request({
    url: '/biz/news/get/' + id,
    method: 'get'
  })
}

// 询价中心
export function postinquiry (data) {
  return request({
    url: '/biz/inquiry',
    method: 'post',
    data: data
  })
}
